<i18n>
ru:
  loading: Идёт загрузка...
  try: Попробуйте
ua:
  loading: Триває завантаження...
  try: Спробуйте
us:
  loading: Loading...
  try: Try
</i18n>

<template>
  <template v-if="actionLocal">
    <div
      :key="actionLocal.ID"
      class="v-stories-container"
    >
      <div
        v-if="actionIndex !== 0"
        class="v-stories-button-left v-d-flex v-align-items-center v-justify-content-start"
        @click="() => outsideButtonClick(false)"
      >
        <div
          v-if="!isSmall"
          class="v-stories-button--round v-pointer v-d-flex v-align-items-center v-justify-content-center"
        >
          <icon-general-arrow-previous />
        </div>
      </div>
      <div
        v-if="actionIndex !== pageStore.ActionsAll!.length - 1"
        class="v-stories-button-right v-d-flex v-align-items-center v-justify-content-end"
        @click="() => outsideButtonClick(true)"
      >
        <div
          v-if="!isSmall"
          class="v-stories-button--round v-pointer v-d-flex v-align-items-center v-justify-content-center"
        >
          <icon-general-arrow-next />
        </div>
      </div>
      <div class="v-stories-progress-bar">
        <common-progress-bar
          v-if="appConfig.VueSettingsPreRun.ActionsBannersStoriesProgress === 'one'"
          :percent="percent"
          theme="popup"
        />
        <div
          v-else
          class="v-d-flex v-flex-row-no-wrap"
        >
          <div
            v-for="(action, indexOfAction) in pageStore.ActionsOnMain"
            :key="`stories-progress-${action.ID}`"
            class="v-w-100 v-mr-xs"
          >
            <common-progress-bar
              v-if="indexOfAction < actionIndex"
              :key="`stories-progress-${action.ID}-pb-hundred-${indexOfAction}`"
              :percent="100"
              theme="popup"
            />
            <common-progress-bar
              v-else-if="indexOfAction === actionIndex"
              :key="`stories-progress-${action.ID}-pb-${indexOfAction}`"
              :percent="percent"
              theme="popup"
            />
            <common-progress-bar
              v-else-if="indexOfAction > actionIndex"
              :key="`stories-progress-${action.ID}-pb-zero-${indexOfAction}`"
              :percent="0"
              theme="popup"
            />
          </div>
        </div>
      </div>
      <div class="v-stories-link v-d-flex v-align-items-center v-justify-content-center">
        <arora-button
          class-name="v-btn v-btn-white v-btn-lg"
          ignore-settings
          v-once
          :label="translate('bannersMinasTirithPopup.try')"
          @click="onActionClick"
        />
      </div>
      <div
        v-if="percent === 0"
        class="v-stories-image v-stories-image--loader"
      >
        <div class="v-stories-image--loader--round">
          <icon-banners-table-bell />
        </div>
        <span v-html="translate('bannersMinasTirithPopup.loading')" />
      </div>
      <div
        :key="actionLocal.ID"
        class="v-stories-image v-pointer"
        v-on-key-press="toggleKeyPress"
        @mousedown="() => imageClick(false)"
        @mouseup="() => imageClick(true)"
        @touchend="() => imageClick(true)"
        @touchstart="() => imageClick(false)"
      >
        <arora-video
          v-if="isImageInfoVideoContent(actionLocal.ImageEventStoriesInfo)"
          autoplay
          :loaded="setUpdatingInterval"
          :video="actionLocal.ImageEventStoriesInfo"
        />
        <arora-nuxt-image
          v-else
          disable-lazy
          :alt="actionLocal.Title"
          :image="actionLocal.ImageEventStoriesInfo"
          image-type="EventStories"
        />
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { IntervalTimer } from '~types/common'
import type { Action } from '~types/pageStore'

import { useWindowSize, vOnKeyPress } from '@arora/common'

const { index } = defineProps<{
  index: number
}>()

const pageStore = usePageStore()
const popupStore = usePopupStore()

const { isImageInfoVideoContent } = useImageInfo()
const { translate } = useI18nSanitized()

const { isSmall } = useWindowSize()
const appConfig = useAppConfig()

const actionLocal = ref<Action | null>(null)
const percent = ref<number>(0)
const interval = ref<IntervalTimer>()

function setUpdatingInterval(durationSeconds: number): void {
  interval.value = new IntervalTimer(() => {
    if (percent.value >= 100) {
      interval.value!.clear()
      setTimeout(() => outsideButtonClick(true), 150) //for progress bar animation
    } else {
      percent.value += 0.1
    }
  }, durationSeconds)

  setTimeout(() => {
    interval.value!.start()
  }, 150)
}

let playing = true
function toggleKeyPress(event: KeyboardEvent): void {
  if (event.code === 'Space') {
    playing = !playing
    togglePlay(playing)
  }
}

function imageClick(play: boolean): void {
  if (isSmall.value) {
    togglePlay(play)
  } else if (!play) {
    playing = !playing
    togglePlay(playing)
  }
}

function togglePlay(play: boolean): void {
  if (play) {
    interval.value?.resume()
  } else {
    interval.value?.pause()
  }
}

onMounted(() => {
  actionIndex.value = index
  actionLocal.value = pageStore.ActionsOnMain![actionIndex.value]

  onCreatedPopup()
})

onUnmounted(() => {
  clear()
})

const actionIndex = ref<number>(-1)

function clear(): void {
  interval.value?.clear()
}

function onCreatedPopup(): void {
  if (interval.value) interval.value.clear()

  popupStore.onClosePopup = clear
  percent.value = 0

  if (actionLocal.value) {
    pageStore.markStoryAsSeen(actionLocal.value.ID)

    if (!isImageInfoVideoContent(actionLocal.value.ImageEventStoriesInfo))
      setUpdatingInterval(appConfig.VueSettingsPreRun.ActionsBannersDelaySec)
  }
}

const onActionClick = (): void => {
  popupStore.closePopup()
  navigateTo(actionLocal.value!.Link)
}
const outsideButtonClick = (isNextButton: boolean): void => {
  interval.value?.clear()
  let action: Action | null | undefined

  if (isNextButton) {
    actionIndex.value++

    action =
      actionIndex.value > pageStore.ActionsAll!.length - 1
        ? null
        : pageStore.ActionsAll![actionIndex.value]
  } else {
    actionIndex.value--

    action = actionIndex.value < 0 ? null : pageStore.ActionsAll![actionIndex.value]
  }

  if (action) {
    actionLocal.value = action
    onCreatedPopup()
  } else {
    popupStore.closePopup()
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

$outsideButtonsSize: 60px;

.v-stories-container {
  display: grid;
  grid-template-columns: $outsideButtonsSize auto $outsideButtonsSize;
  grid-template-rows: 1fr 3fr 1fr;
  max-height: 95vh;
  gap: 0;
  width: calc(((95vh * 9) / 16) + ($outsideButtonsSize * 2));
  max-width: 95vw;
  margin-left: calc(-1 * $outsideButtonsSize);
  user-select: none;

  @include mixins.sm {
    min-width: calc(100% + 10px);
    margin-left: -5px;
    margin-top: -5px;
  }
}

.v-stories-progress-bar {
  grid-area: 1 / 2 / 2 / 3;
  z-index: 1;
  padding: 20px 40px;
  user-select: none;

  .v-progress-bar {
    width: 100%;
  }

  @include mixins.sm {
    padding: 20px 16px;

    grid-area: 1 / 1 / 2 / 4;
  }
}

.v-stories-button-left {
  z-index: 1;
  grid-area: 2 / 1 / 3 / 2;
  user-select: none;
}

.v-stories-button-right {
  z-index: 1;
  grid-area: 2 / 3 / 3 / 4;
  user-select: none;
}

.v-stories-button {
  &--round {
    width: 40px;
    height: 40px;
    background: variables.$BodyElementsBackgroundOpaq25;
    border-radius: 50%;
    @include mixins.trans;

    .v-svg-arrow-prev > path,
    .v-svg-arrow-next > path {
      stroke: variables.$BodyElementsBackground;
    }

    &:hover {
      background: variables.$BodyElementsBackground;
      box-shadow: variables.$FloatShadow;

      .v-svg-arrow-prev > path,
      .v-svg-arrow-next > path {
        stroke: variables.$BodyTextColor;
      }
    }
  }
}

.v-stories-link {
  z-index: 1;
  grid-area: 3 / 2 / 4 / 3;

  .v-btn {
    user-select: none;
  }
}

.v-stories-image {
  grid-area: 1 / 2 / 4 / 3;
  overflow: hidden;
  border-radius: variables.$BorderRadius;

  .v-img-fluid {
    user-select: none;
    width: 100%;
  }

  &--loader {
    background: #d9d9d9;
    color: #fff;
    font-size: 2rem;

    height: min(1920px, 95vh);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    user-select: none;

    &--round {
      background: #fff;
      box-shadow: variables.$FloatShadow;
      width: 290px;
      padding: 65px 45px 100px 45px;
      border-radius: 50%;
      margin-bottom: 50px;
    }
  }

  video {
    width: 100%;
    user-select: none;
  }
  @include mixins.sm {
    grid-area: 1 / 1 / 4 / 4;
  }
}
</style>
